export const DEFAULT_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1581981370/defaults/cover-photo-default.jpg';
export const DEFAULT_WORK_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1605566479/assets/Communo-external-post-image-01_2x.jpg';
export const DEFAULT_ONBOARDING_COVER_PHOTO_URL =
  '/assets/communo/images/account.jpg';
export const DEFAULT_ONBOARDING_LOGO_URL =
  '/assets/communo/images/logo-onboarding.png';
export const DEFAULT_LOGIN_COVER_PHOTO_URL =
  '/assets/communo/images/onboarding-login-email.jpg';
export const DEFAULT_RESET_COVER_PHOTO_URL =
  '/assets/communo/images/onboarding-user-password.jpg';
export const DEFAULT_FORGOT_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-forgot.jpg';
export const DEFAULT_SENT_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1591640410/onboarding/onboarding-login-magic.jpg';
export const DEFAULT_LOGIN_REQUIRED_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-user-password.jpg';
export const DEFAULT_UPGRADE_REQUIRED_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-upgrade-pro.jpg';
export const DEFAULT_ENTER_PASSWORD_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-user-password.jpg';
export const DEFAULT_WELCOME_BACK_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-welcome-back.jpg';
export const DEFAULT_WRONG_PASSWORD_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-password-incorrect.jpg';
export const DEFAULT_NOT_FOUND_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-not-found.jpg';
export const DEFAULT_TEAM_INVITE_COVER_PHOTO_URL =
  '/assets/communo/images/onboarding-login-email.jpg';
export const DEFAULT_TEAM_LOGIN_COVER_PHOTO_URL =
  'https://res.cloudinary.com/communo/image/upload/v1591823474/onboarding/onboarding-login-team.jpg';
