import stage from './stage';
import production from './production';

// DO NOT UPDATE THE VALUE OF THE BRAND
// FEATURE FLAGS ON THIS REPO.
// UPDATE THEM THROUGH THE ASSOCIATED BRAND PACKAGE.
export const brandStageFeatureFlags = {
  featureFlags: { ...stage.featureFlags },
};

export const brandProdFeatureFlags = {
  featureFlags: { ...production.featureFlags },
};
